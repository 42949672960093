<template>
  <div class="flex flex-col h-screen">
    <NavbarComponent />
    <main class="mt-20">
      <div class="grid grid-cols-1 md:grid-cols-5 gap-2 p-3">
        <!-- Removed Image and added package selection boxes -->
        <div class="col-span-2 flex flex-col space-y-4 p-4">
          <!-- Accounting Program Dropdown -->
          <h3>Välj bokföringsprogram & paket</h3>
          <h6>Bokföringsprogram</h6>
          <v-select 
            :options="subscriptions.accounting_programs" 
            v-model="registerForm.accounting_program" 
            label="swe_name"
            @input="selectAccountingProgram"
          >
          </v-select>
          <!-- Package Selection Boxes -->
          <h6>Paket</h6>
          <div v-for="pkg in adjustedPackages" :key="pkg.id" @click="selectPackage(pkg)" 
               :class="['package-box', registerForm.package === pkg.be_name ? 'selected' : '']">
            <h2>{{ pkg.swe_name }}</h2>
            <p>Pris/mån: {{ pkg.adjustedMonthlyPrice }} SEK exkl. moms (årlig prenumerationskostnad debiteras på årsbasis)</p>
            <p>Funktioner: {{ pkg.features.join(', ') }}</p>
          </div>
        </div>
        <div class="col-span-3 flex justify-center">
          <div class="flex flex-col md:w-4/5 mx-auto mt-6">
            <h1 class="text-gray-900 text-4xl">
              {{ $t("RegisterPage.CardTitle") }}
            </h1>
            <p v-if="selectedSubscription.package" class="text-gray-400">
              {{
                $t("RegisterPage.CardSubtitle", {
                  packaging: selectedSubscription.package,
                  plan: selectedSubscription.payment_plans[resultName],
                  accounting:
                    selectedSubscription.accounting_programs[resultName],
                })
              }}
            </p>

            <validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2 flex flex-col justify-between"
                @submit.prevent="validationForm"
              >
                <div class="grid grid-cols-1 md:grid-cols-2 gap-5">
                  <div class="mt-1">
                    <!-- email -->
                    <b-form-group
                      :label="$t('RegisterPage.FullNameLabel')"
                      label-for="register-club"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.FullNameLabel')"
                        rules="required"
                      >
                        <b-form-input
                          id="register-club"
                          v-model="registerForm.full_name"
                          :state="errors.length > 0 ? false : null"
                          :disabled="
                            !registerForm.package ||
                            !registerForm.accounting_program
                          "
                          class="p-2"
                          name="register-club"
                          placeholder=""
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!-- <div class="mt-1">
                    <b-form-group
                      :label="$t('RegisterPage.ClubLabel')"
                      label-for="register-club"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.ClubLabel')"
                        rules="required"
                      >
                        <b-form-input
                          id="register-club"
                          v-model="registerForm.golfclub_name"
                          :state="errors.length > 0 ? false : null"
                          :disabled="
                            !registerForm.package ||
                            !registerForm.accounting_program
                          "
                          class="p-2"
                          name="register-club"
                          placeholder=""
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group> 
                  </div>-->

                  <!-- <div class="">
                    <b-form-group
                      :label="$t('RegisterPage.ClubNumberLabel')"
                      label-for="login-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.ClubNumberLabel')"
                        rules="required"
                      >
                        <b-form-input
                          id="register-golf-num"
                          v-model="registerForm.golfclub_number"
                          :state="errors.length > 0 ? false : null"
                          class="p-2"
                          name="register-golf-num"
                          placeholder=""
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div> -->
                  <div class="">
                    <b-form-group :label="$t('RegisterPage.Club')">
                      <v-select
                        v-model="selectedOrganization"
                        :options="organizations"
                        label="club_name"
                        @input="handleOrganizationInput"
                        :disabled="
                          !registerForm.package ||
                          !registerForm.accounting_program
                        "
                      ></v-select>
                    </b-form-group>
                  </div>
                  <!-- Club Name Field (Read-Only) -->
                  <div class="mt-1">
                      <b-form-group :label="$t('RegisterPage.ClubLabel')" label-for="display-club-name">
                          <b-form-input
                              id="display-club-name"
                              v-model="golfclub_name"
                              readonly
                              class="p-2"
                              name="display-club-name"
                              placeholder=""
                          ></b-form-input>
                      </b-form-group>
                  </div>

                  <!-- Club Number Field (Read-Only) -->
                  <div class="">
                      <b-form-group :label="$t('RegisterPage.ClubNumberLabel')" label-for="display-club-number">
                          <b-form-input
                              id="display-club-number"
                              v-model="golfclub_number"
                              readonly
                              class="p-2"
                              name="display-club-number"
                              placeholder=""
                          ></b-form-input>
                      </b-form-group>
                  </div>


                  <div style="display: none !important">
                    <!-- email -->
                    <b-form-group
                      :label="$t('RegisterPage.CustomerNumberLabel')"
                      label-for="login-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.CustomerNumberLabel')"
                        rules="required"
                      >
                        <b-form-input
                          id="register-orga"
                          v-model="registerForm.customer_number"
                          :state="errors.length > 0 ? false : null"
                          class="p-2"
                          name="register-orga"
                          placeholder=""
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div>
                  <!-- <div class="mt-1">
                      <b-form-group :label="$t('RegisterPage.EmailLabel')" label-for="register-orga">
                          <b-form-input
                              id="display-email"
                              v-model="email"
                              readonly
                              class="p-2"
                              name="display-email"
                              placeholder=""
                          ></b-form-input>
                      </b-form-group>
                  </div> -->
                  <!-- <div class="">
                    <b-form-group
                      :label="$t('RegisterPage.EmailLabel')"
                      label-for="register-orga"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.EmailLabel')"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="registerForm.email"
                          :state="errors.length > 0 ? false : null"
                          class="p-2"
                          name="login-email"
                          placeholder=""
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div> -->

                  <div class="">
                    <!-- email -->
                    <b-form-group
                      :label="$t('RegisterPage.BillingEmailLabel')"
                      label-for="login-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.BillingEmailLabel')"
                        vid="email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="registerForm.billing_email"
                          :state="errors.length > 0 ? false : null"
                          class="p-2"
                          name="login-email"
                          placeholder=""
                          :disabled="
                            !registerForm.package ||
                            !registerForm.accounting_program
                          "
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <!-- <div class="">
                    <b-form-group
                      :label="$t('RegisterPage.BillingTypeLabel')"
                      label-for="login-type"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.BillingTypeLabel')"
                        rules="required"
                      >
                        <b-form-input
                          id="login-type"
                          v-model="billing_type"
                          :state="errors.length > 0 ? false : null"
                          class="p-2"
                          name="login-type"
                          disabled
                          placeholder=""
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div> -->

                  <div class="">
                    <!-- email -->
                    <b-form-group
                      :label="$t('RegisterPage.InvoiceLabel')"
                      label-for="invoice-address"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.InvoiceLabel')"
                        rules="required"
                      >
                        <b-form-input
                          id="invoice-address"
                          v-model="registerForm.invoice_address"
                          :state="errors.length > 0 ? false : null"
                          class="p-2"
                          name="invoice-address"
                          placeholder=""
                          :disabled="
                            !registerForm.package ||
                            !registerForm.accounting_program
                          "
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="">
                    <!-- email -->
                    <b-form-group
                      :label="$t('RegisterPage.CityLabel')"
                      label-for="login-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.CityLabel')"
                        rules="required"
                      >
                        <b-form-input
                          id="login-city"
                          v-model="registerForm.city"
                          :state="errors.length > 0 ? false : null"
                          class="p-2"
                          name="login-city"
                          placeholder=""
                          :disabled="
                            !registerForm.package ||
                            !registerForm.accounting_program
                          "
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div class="grid grid-cols-1 md:grid-cols-2 md:gap-3">
                    <!-- email -->
                    <b-form-group
                      :label="$t('RegisterPage.CountryLabel')"
                      label-for="login-country"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.CountryLabel')"
                        rules="required"
                      >
                        <select
                          v-model="registerForm.country"
                          :class="errors.length > 0 ? 'border-red-500' : null"
                          class="w-full bg-white rounded custom-border focus:outline-none focus:border-blue-300 text-gray-600 p-1"
                        >
                          <option
                            :value="country.value"
                            v-for="(country, i) in countryList"
                            :key="i"
                          >
                            {{ country.text }}
                          </option>
                        </select>
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>

                    <!-- email -->
                    <b-form-group
                      :label="$t('RegisterPage.ZipLabel')"
                      label-for="login-zip"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="$t('RegisterPage.CityLabel')"
                        vid="email"
                        rules="required"
                      >
                        <b-form-input
                          id="login-zip"
                          v-model="registerForm.zip_code"
                          :state="errors.length > 0 ? false : null"
                          class="p-2"
                          name="login-zip"
                          placeholder=""
                        />
                        <small class="text-danger">
                          {{ errors[0] }}
                        </small>
                      </validation-provider>
                    </b-form-group>
                  </div>

                  <div>
                    <b-form-checkbox
                      id="checkbox-2"
                      v-model="registerForm.onboarding_care"
                      name="checkbox-2"
                    >
                      {{ $t("RegisterPage.OnboardLabel") }}
                    </b-form-checkbox>
                  </div>
                </div>

                <div class="flex justify-end mt-10 md:mt-32">
                  <!-- checkbox -->
                  <b-form-group>
                    <div class="flex justify-between space-x-3">
                      <span>
                        {{ $t("RegisterPage.ReadLabel") }}
                        <span
                          class="text-theme-sidebar font-semibold"
                          style="cursor: pointer"
                          @click="downloadTermsOfPurchase"
                          >{{ $t("RegisterPage.termsOfAgreement") }}</span
                        >
                      </span>
                      <b-form-checkbox
                        id="remember-me"
                        v-model="agreementStatus"
                        name="checkbox-1"
                      >
                      </b-form-checkbox>
                    </div>
                  </b-form-group>
                </div>

                <div class="flex justify-end mt-10 md:mt-10">
                  <!-- submit buttons -->
                  <button
                    type="submit"
                    :disabled="processing"
                    class="flex px-4 py-1 focus:outline-none bg-theme-sidebar text-white rounded-md"
                  >
                    <span
                      v-if="processing"
                      class="flex justify-center items-center space-x-2 whitespace-nowrap"
                    >
                      <svg
                        class="animate-spin h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span
                        class="text-xl md:text-2xl text-white whitespace-nowrap"
                        >{{ $t("Message.wait") }}</span
                      >
                    </span>
                    <span
                      v-else
                      class="text-xl md:text-xl whitespace-nowrap text-white"
                    >
                      {{ $t("RegisterPage.Button") }}
                    </span>
                  </button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </div>
    </main>
    <SignupExtension
      v-if="showSignupExtension"
      :toggleSignupExtension="toggleSignupExtension"
      :toggleChooseVersion="toggleChooseVersion"
      :setExistingUserData="setExistingUserData"
      :setToApiVersion="setToApiVersion"
    />
    <ChooseVersion
      :modifyGitApiUser="modifyGitApiUser"
      :toggleChooseVersion="toggleChooseVersion"
      :mapSignupInputWithData="mapSignupInputWithData"
      :existingUserData="existingUserData"
      v-if="showChooseVersion"
    />
    <FooterComponent />
  </div>
</template>

<script>
/* eslint-disable global-require */
import NavbarComponent from "./components/Navbar.vue";
import download from "downloadjs";

import FooterComponent from "./components/Footer.vue";
import FaqRenderVue from "./custom/AccordionFaq.vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import CountryJson from "@/mixins/countriesCode.json";

import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { mapGetters } from "vuex";
import SignupExtension from "./components/SignupExtension.vue";
import ChooseVersion from "./components/ChooseVersion.vue";
import vSelect from "vue-select";

export default {
  name: "SignUpPage",
  components: {
    ValidationProvider,
    ValidationObserver,
    NavbarComponent,
    FooterComponent,
    FaqRenderVue,
    SignupExtension,
    ChooseVersion,
    vSelect
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      subscriptions: null,
      config: useAppConfig(),
      existingUserData: null,
      showChooseVersion: false,
      showSignupExtension: true,
      showModal: true,
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/login-v2.svg"),
      required,
      email,
      registerForm: {
        // email: "",
        customer_number: "",
        full_name: "",
        billing_email: "",
        // golfclub_name: "",
        // golfclub_number: "",
        billing_type: "yearly",
        country: "",
        invoice_address: "",
        city: "",
        zip_code: "",
        // cost: "",
        onboarding_care: false,
        sla_addon: false,
        package: "",
        accounting_program: "",
        is_git_api_user: true,
        organization_uuid: ""
      },
      billing_type: "",
      countryList: [],
      selectedSubscription: { package: "", accounting_program: "" },
      processing: false,
      agreementStatus: false,
      organizations: [],
      selectedOrganization: null,
      golfclub_name: "",
      golfclub_number: "",
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
    resultName() {
      if (this.getLang == "") {
        return this.swedishLang ? "swe_name" : "eng_name";
      } else return this.getLang == "sv" ? "swe_name" : "eng_name";
    },
    getLang() {
      return this.config.lang.value;
    },
    adjustedPackages() {
      if (!this.subscriptions || !this.subscriptions.accounting_programs || !this.subscriptions.packages) {
        return [];
      }

      const selectedAccountingProgram = this.subscriptions.accounting_programs.find(
        prog => prog.be_name === this.registerForm.accounting_program
      );

      const addOnPrice = selectedAccountingProgram ? selectedAccountingProgram.monthly_add_on : 0;

      return this.subscriptions.packages.map(pkg => ({
        ...pkg,
        adjustedMonthlyPrice: pkg.monthly_price + addOnPrice,
      }));
    },
    ...mapGetters({
      GET_LOCALE: "appConfig/getLocale",
    }),
  },
  mounted() {
    this.countryList = CountryJson.map((v) => {
      return { value: v.name, text: v.name, iso: v.iso };
    });

    this.getLandingPackages();
    this.$bvModal.show("modal-owner-check");

    this.locale = localStorage.getItem("lang");
    const subscriptions = localStorage.getItem("__getStarted__");

    if (this.$route.query.q) {
      this.popup(
        this.$t("Error.TokenExpired.Message"),
        "danger",
        this.$t("Error.TokenExpired.Title"),
        "AlertTriangleIcon"
      );
    }

    if (subscriptions) {
      const details = JSON.parse(subscriptions);
      this.selectedSubscription = details;
      this.registerForm.package = details.package;
      this.registerForm.accounting_program =
        details.accounting_programs.be_name;
      this.billing_type = details.payment_plans.be_name;
      this.registerForm.billing_type = details.payment_plans.be_name;
    }

  },
  created() {
    this.fetchAllOrganizations(); // Fetch all organizations initially immediately
  },
  methods: {
    getCountryNameByIso(iso) {
      const countries = CountryJson.map((v) => {
        return { value: v.name, text: v.name, iso: v.iso };
      });

      const targetCountry = countries?.find((country) => country.iso === iso);
      return targetCountry.value || iso;
    },
    selectPackage(pkg) {
      this.registerForm.package = pkg.be_name;
    },
    selectAccountingProgram(selectedProgram) {
      this.registerForm.accounting_program = selectedProgram.be_name;
    },
    mapSignupInputWithData() {
      const {
        customer_number,
        old_contract_end_date,
        old_contract_numbers,
        // golfclub_name,
        // golfclub_number,
        invoice_address,
        city,
        zip_code,
        country,
        billing_email,
      } = this.existingUserData;
      this.registerForm["customer_number"] = customer_number;
      this.registerForm["old_contract_end_date"] = old_contract_end_date;
      this.registerForm["old_contract_numbers"] = old_contract_numbers;
      // this.registerForm["golfclub_name"] = golfclub_name;
      // this.registerForm["golfclub_number"] = golfclub_number;
      this.registerForm["invoice_address"] = invoice_address;
      this.registerForm["city"] = city ? city : undefined;
      this.registerForm["zip_code"] = zip_code ? zip_code : undefined;
      this.registerForm["country"] = country
        ? this.getCountryNameByIso(country)
        : undefined;
      this.registerForm["billing_email"] = billing_email
        ? billing_email
        : undefined;
    },
    setExistingUserData(data) {
      this.existingUserData = data?.data;
        const organization = this.organizations.find(org => org.uuid === this.existingUserData.organization_uuid);
        this.handleOrganizationInput(organization);  // Handle the organization object
    },

    toggleChooseVersion() {
      this.showChooseVersion = !this.showChooseVersion;
    },
    toggleSignupExtension() {
      this.showSignupExtension = !this.showSignupExtension;
    },
    modifyGitApiUser(type) {
      this.mapSignupInputWithData();
      this.registerForm.is_git_api_user = type;
    },

    setToApiVersion() {
      this.registerForm.is_git_api_user = true;
    },

    fetchAllOrganizations() {
        const initialUrl = '/organizations/';
        this.fetchOrganizationsRecursively(initialUrl)
            .catch(error => {
                console.error("Error fetching organizations:", error);
            });
    },
    fetchOrganizationsRecursively(url) {
        return this.$useJwt.getOrganizations({}, url).then(response => {
            this.organizations = this.organizations.concat(response.data.results);
            if (response.data.next) {
                return this.fetchOrganizationsRecursively(response.data.next);
            } else {
                return this.organizations;
            }
        });
    },
    handleOrganizationInput(value) {
      if (value && value.uuid) {
          this.selectedOrganization = value;
          this.registerForm.organization_uuid = value.uuid;
          this.golfclub_name = value.club_name;
          this.golfclub_number = value.organization_number;
      } else {
          this.registerForm.organization_uuid = "";
          this.golfclub_name = "";
          this.golfclub_number = "";
          this.selectedOrganization = null;
      }
    },
    validationForm() {
      if (this.registerForm.package === "") {
        this.popup(
          this.$t("RegisterPage.PackageRequired"),
          "danger",
          this.$t("Message.Failed"),
          "AlertTriangleIcon"
        );
        return false;
      } else if (this.registerForm.accounting_program === "") {
        this.popup(
          this.$t("RegisterPage.AccountProgramRequired"),
          "danger",
          this.$t("Message.Failed"),
          "AlertTriangleIcon"
        );
        return false;
      } else if (!this.agreementStatus) {
        this.popup(
          this.$t("RegisterPage.AgreeTerms"),
          "danger",
          this.$t("Message.Failed"),
          "AlertTriangleIcon"
        );
        return false;
      } else if (!this.selectedOrganization) {
        this.popup(
          this.$t("RegisterPage.organizationMandatory"),
          "danger",
          this.$t("Message.Failed"),
          "AlertTriangleIcon"
        );
        return false;
      } else {
        for (const [key, value] of Object.entries(this.registerForm)) {
          if (key === 'billing_type') {
            this.registerForm['billing_type'] = 'yearly';
          }
          if (key === 'customer_number' && (value === "" || value === null)) {
            delete this.registerForm[key];  // Remove 'customer_number' from the payload
            continue;  // Skip the current loop iteration
          }
          if (
            this.registerForm[key] === "" ||
            this.registerForm[key] === null ||
            this.registerForm[key] === undefined
          ) {
            this.popup(
              `${key.replace("_", " ")} är obligatoriskt`,
              "danger",
              this.$t("Message.Failed"),
              "AlertTriangleIcon"
            );

            return false;
          }
          // if (key === "golfclub_number") {
          //   if (this.validateGolfigoNumber(this.registerForm[key]) === false) {
          //     this.popup(
          //       this.$t("invalidGolfNumber"),
          //       "danger",
          //       this.$t("Message.Failed"),
          //       "AlertTriangleIcon"
          //     );
          //     return false;
          //   }
          // }
        }
        this.processing = true;

        const {
          // email,
          full_name,
          billing_type,
          customer_number,
          old_contract_end_date,
          old_contract_numbers,
          package: packageRenamed,
          accounting_program,
          is_git_api_user, 
          onboarding_care
        } = this.registerForm;
        const payloadCommon = {
          // email,
          full_name,
          billing_email: this.registerForm.billing_email, // Added this manually as it was missing from the destructuring
          billing_type: billing_type,
          country: this.registerForm.country,  // Added this manually as it was missing from the destructuring
          invoice_address: this.registerForm.invoice_address,  // Added this manually as it was missing from the destructuring
          city: this.registerForm.city,  // Added this manually as it was missing from the destructuring
          zip_code: this.registerForm.zip_code,  // Added this manually as it was missing from the destructuring
          organization_uuid: this.selectedOrganization.uuid,  // Use the UUID from the selected organization
          package: packageRenamed,
          accounting_program,
          onboarding_care
        };
        const apiVersionPayload = {
          ...payloadCommon,
          is_git_api_user: true,
          customer_number, 
          old_contract_end_date,
          old_contract_numbers,
        };

        const fileVersionPayload = {
          ...payloadCommon,
          is_git_api_user: false,
          customer_number,
          old_contract_end_date,
          old_contract_numbers,
        };

        const modifiedForm = is_git_api_user
          ? apiVersionPayload
          : fileVersionPayload;
        this.$useJwt
          .register(modifiedForm)
          .then((res) => {
            const { data } = res;
            this.processing = false;
            this.clearFormData();
            this.popup(
              this.$t("RegisterPage.WelcomeMessage"),
              "success",
              `${this.$t("Message.welcome")} ${this.registerForm.full_name}`,
              "CoffeeIcon"
            );
            this.$router.replace({ name: "login" });
          })
          .catch((error) => {
            this.processing = false;
            if (error.response) {
              let msg =
                this.GET_LOCALE === "sv"
                  ? error.response.data.message
                  : error.response.data.eng_message;

              this.$refs.loginValidation.setErrors({
                email: [msg],
              });
              this.popup(
                msg,
                "danger",
                this.$t("Message.Failed"),
                "AlertTriangleIcon"
              );
            }
          });
      }
    },
    validateGolfigoNumber(number) {
      if (!number) {
        return false;
      }
      let num = number.split("-");
      if (num.length > 2) {
        return false;
      }
      if (num[0].length > 6 || num[0].length < 6) {
        return false;
      }
      if (num[1].length > 4 || num[1].length < 4) {
        return false;
      }
      return true;
    },
    downloadTermsOfPurchase() {
      this.$useJwt
        .customLanding(
          { URL: "/terms_of_purchase", method: "get" },
          {
            responseType: "blob",
            headers: {
              "Content-Type": "application/pdf",
            },
          }
        )
        .then((res) => {
          const content = res.headers["content-type"];
          download(res.data, "termsOfPurchase", content);
        })
        .catch((err) => {
          this.popup(
            err.response.data.message,
            "danger",
            this.$t("Message.Failed"),
            "AlertTriangleIcon"
          );
        });
    },
    popup(message, variant, title, icon) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: icon,
          variant: variant,
          text: message,
        },
      });
    },
    clearFormData() {
      Object.keys(this.registerForm).forEach((element) => {
        if (["onboarding_care", "sla_addon"].includes(element) === true) {
          this.registerForm[element] = false;
        } else {
          this.registerForm[element] = "";
        }
      });
    },
    async getLandingPackages() {
      try {
        const res = await this.$useJwt.getLandingPackages();
        if (res.status === 200) {
          this.subscriptions = {
            ...res.data.data,
            packages: res.data.data.packages.sort(
              (a, b) => parseFloat(a.monthly_price) - parseFloat(b.monthly_price)
            ),
          };
        }
      } catch (error) {
        console.error("Failed to fetch landing packages:", error);
      }
    },
  },
  watch: {
    'registerForm.package': function(newVal, oldVal) {
      // update local storage
      localStorage.setItem("__getStarted__", JSON.stringify(this.registerForm));
    },
    'registerForm.accounting_program': function(newVal, oldVal) {
      // update local storage
      localStorage.setItem("__getStarted__", JSON.stringify(this.registerForm));
    },
    subscriptions: {
      handler(newValue, oldValue) {
        if (newValue && newValue.accounting_programs) {
          const query = this.$route.query;
          if (query['acct-pm']) {
            const acctProgram = newValue.accounting_programs.find(
              prog => prog.be_name === query['acct-pm']
            );
            if (acctProgram) {
              this.registerForm.accounting_program = acctProgram.be_name;
            }
          }
        }
      },
      deep: true, // To watch nested properties
      immediate: true, // To run the handler immediately with the current value of the expression
    },
  },

};
</script>

<style scoped>
  .package-box {
    border: 1px solid #ccc;
    padding: 16px;
    cursor: pointer;
    transition: 0.3s;
  }
  .package-box:hover {
    border-color: #aaa;
  }
  .package-box.selected {
    border-color: green;
    background-color: #f0fff0;
  }
</style>

